/* Цветовая палитра / Color palette */

:root
{
  --main-header-color: #ffffff;
  --main-header-border-color: #b6b6b6;
  --main-color-dark: #1a75bc;
  --main-color: #009bdb;
  --sub-block-color: #e1fdee;
  --stat-table-buttons-color: rgb(165, 194, 247);
  --sidebar-bg: #161616;
  --sidebar-focus: #303030;
  --sidebar-text: #a0a0a0;
  --sidebar-text-focus: #ffffff;

  --bottom-header:#9b9b9b;
  --black: #121212;
  --white: #fafafa;
  --border-grey: #e0e0e0;
  --main-color-grey: #e9e9e9;
  --stat-table-color: #e6f2f9;
  --stat-table-border: #bcc9d2;
  --stat-table-select: #829eaf;
  --stat-table-color-font: #1e2836;
}