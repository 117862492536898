body {
    overflow: overlay;
}

h1 {
    display: inline;
    position: relative;
    z-index: 1;

}

h1, h4
{
    font-size: 22px !important;
    font-family: "Arial" !important;
}

h2, h3
{
    font-size: 16px !important;
    font-family: "Arial" !important;
}

a
{
    color: var(--main-color);
}

a:hover
{
    color: var(--main-color-dark);
}

a:active, a:focus {
    outline: none;
    text-decoration: none;
    color: var(--main-color-dark);
}

ul.pagination li.paginate_button a:hover{
    cursor:pointer;
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
}

input:focus, select:focus
{
    outline: 1px solid var(--main-color);
}

.remember-flex {
    align-self: center;
    margin-top: 5px;
}

.row-flex {
    display: flex;
}

.box-mailing {
    padding-left: 5px!important;
}

.box-body-mailing {
    padding-left: 5px!important;
}

tspan {
    font-weight: normal !important;
    font-size: 13px;
}

span.main-title
{
    font-weight: normal;
    font-style: italic;
}

td.td-settings
{
    width:84px;
    padding: 0px !important;
}

th.td-settings
{
    width:84px;
    padding: 10px 0px !important;
    text-align: center;
}

th.td-settings::after
{
    content: none !important;
}

td.td-settings-l
{
    width:84px;
    padding: 0px 0 !important;
    text-align: left;
}

th.td-settings-l
{
    padding: 10px auto !important;
    text-align: left;
}

th.td-settings-l::after
{
    content: none !important;
}

div.jsoneditor
{
    border-color: var(--main-color-dark);
}

div.jsoneditor-menu
{
    background-color: var(--main-color);
    border-bottom-color: var(--main-color-dark);
}

.datetimepicker table tr td.active, .datetimepicker table tr td.active:hover, .datetimepicker table tr td.active.disabled, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td span.active, .datetimepicker table tr td span.active:hover, .datetimepicker table tr td span.active.disabled, .datetimepicker table tr td span.active.disabled:hover
{
    background-image: -webkit-gradient(linear,left top, left bottom,from(var(--main-color)),to(var(--main-color-dark))) !important;
    background-image: linear-gradient(to bottom,var(--main-color),var(--main-color-dark)) !important;
}

.datetimepicker td, .datetimepicker th, .datetimepicker table tr td span
{
    border-radius: 0px !important;
}

.daterangepicker td, .daterangepicker th
{
    border-radius: 0px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: var(--main-color);
}

.daterangepicker .input-mini.active
{
    border: 1px solid var(--main-color);
    border-radius: 4px;
}

.canals
{
    margin-top: 7px;
}

.ok
{
    width: 140px;
}

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
    background-color: var(--sidebar-bg);
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: var(--main-header-color);
    color: var(--main-color-dark);
    font-size: 2em;
    padding-top: 8px;
    padding-bottom: 1px;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.skin-blue .main-header .navbar .sidebar-toggle {
    color: var(--main-color-dark);
    padding-left: 0px;
    -webkit-transition: font-size 0.2s, padding-top 0.2s, padding-bottom 0.2s;
    transition: font-size 0.2s, padding-top 0.2s, padding-bottom 0.2s;
    margin-top: 3px;
}

.skin-blue .sidebar-menu > li:hover > a
{
    background-color: var(--sidebar-focus);

}

.skin-blue .sidebar-menu > li.active, .skin-blue .sidebar-menu > li, .skin-blue .sidebar-menu > li
{
    -webkit-transition: background-color 0.2s linear,  border-left 0.2s linear;
    transition: background-color 0.2s linear,  border-left 0.2s linear;
}

.skin-blue .sidebar-menu > li.active, .skin-blue .sidebar-menu > li:hover, .skin-blue .sidebar-menu > li:active
{
    background-color: var(--sidebar-focus);
    border-left: solid 3px var(--main-color);
}

.skin-blue .sidebar-menu .button-add:hover
{
    background-color: var(--sidebar-bg);
    border: none;

}

.sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini img {
    height: 30px;
}

.sidebar-menu {
    margin-top: 54px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    vertical-align: middle;
    border: 1px solid #dddddd;
}

table.table-bordered.dataTable {
    border-collapse: collapse !important;
}

table p {
    margin: 0;
}

table .dropdown-menu > li > a
{
    cursor: pointer;
}

.box-header > .fa, .box-header > .glyphicon, .box-header > .ion, .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin-top: 1px;
    margin-bottom: 4px;
    line-height: 1;
}

.collapsed-box .box-header.with-border {
    border-bottom: none;
}

.modal_add
{
    margin-left: 10px;
}

@media (max-width: 991px) {
    .navbar-custom-menu .navbar-nav > li > a {
        padding-top: 8px;
    }

    .navbar-nav > .user-menu .user-image {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {

    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
        margin: 0;
        padding: 0;
    }

    .sidebar-mini .main-header .logo > .logo-mini img {
        height: 30px;
    }

    .main-header .logo {
        width: auto;
    }

    .sidebar-menu {
        margin-top: 125px;
    }

    .main-header .logo .logo-lg {
        display: none;
    }

    .main-header {
        display: flex;
    }

    .main-header .logo .logo-mini {
        display: block;
    }

    .navbar-custom-menu .navbar-nav > li > a {
        padding-top: 8px;
    }

    .skin-blue .main-header .navbar .sidebar-toggle {
        padding-left: 10px;
    }

    .skin-blue .main-header .navbar .dropdown-menu li a {
        color: #777;
    }

    .skin-blue .main-header .navbar .dropdown-menu li a:hover {
        color: #f2f2f2;
        background-color: #777;
    }

    .content-header {
        margin-top: 30px;
    }

    .user-avatar {
        height: 70px;
    }

    .navbar-nav > .user-menu .user-image {
        margin-top: 0px !important;
        margin-right: 0px !important;
    }

    .sidebar {
        margin-top: -71px;
    }
}

.sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    font-size: 18px;
    -ms-grid-row-align: center;
    align-self: center;
    margin-left: -6px;
    margin-top: 3px;
}

.main-header .sidebar-toggle:before {
    content: '';
}

@media (max-width: 767px) {
    .navbar-custom-menu .navbar-nav > li > a {
        padding-top: 8px;
        padding-bottom: 5px;
    }

}

.skin-blue .main-header .navbar .nav > li > a {
    color: #000;
    height: 54px;
    display: table-cell;
    vertical-align: middle;
}

.skin-blue .main-header .navbar .nav > li > a:hover, .skin-blue .main-header .navbar .nav >
li > a:active, .skin-blue .main-header .navbar .nav > li > a:focus, .skin-blue .main-header .navbar .nav .open >
a, .skin-blue .main-header .navbar .nav .open > a:hover, .skin-blue .main-header .navbar .nav .open >
a:focus, .skin-blue .main-header .navbar .nav > .active > a {
    color: #262626;
}

.navbar-custom-menu > .navbar-nav > li {
    height: 54px;
}

.navbar-custom-menu {
    margin-right: 16px;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 7px 5px;
}

.icon-header {
    width: 39px;
}

.login-cpa img {
    filter: brightness(30%);
}

.skin-blue .main-header .navbar, .skin-blue .main-header .logo {
    background-color: var(--main-header-color);
    border-bottom: solid 1px;
    height: 54px;
    border-color: var(--main-header-border-color);
    text-align: left;
    -webkit-transition: border-bottom 0.2s linear;
    transition: border-bottom 0.2s linear;
}

.box-footer .col-sm-11 button {
    margin-right: -15px;
}

.skin-blue .main-header .logo:hover {
    background-color: var(--main-header-color);
    border-bottom: solid 4px var(--main-color-dark);
    /* border-bottom: 4px solid white;*/
}

.control-label
{
    padding-top: 8px;
}

.logo-lg {
    margin-left: 5px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--main-color);
    color: white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
}

.settings_delete
{
    border-top: 1px solid #ccc;
}

.select2-container--default .select2-selection--single .select2-selection__clear
{
    margin-right: 37px;
    margin-top: 2px;
}

.over_scroll {
    overflow: auto;
}

.ps10 {
    padding: 0 10px 130px;
}

.navbar-nav > .user-menu .user-image {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -7px;
}

.btn-success {
    background-color: var(--main-color)!important;
    border-color: var(--main-color-dark)!important;
}

.btn-tab
{
    margin: 0;
    cursor: pointer;
    border: 1px solid rgb(204, 204, 204);
    background: #424242;
    color: #fff;
    border-radius: 0;
    padding-bottom: 6px;
    padding-top: 8px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: normal;
}

.btn-settings
{
    border: none;
    width: 42px;
    height: 38px;
    font-size: 1.8em;

}

.btn-settings img
{
    position: absolute;
    top: 7px;
    left: 7px;
}

.btn-settings:active:focus,
.btn-settings.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-settings:active.focus,
.btn-settings.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #747474;
    color: white;
    border: none;
}

.dropdown-menu > li:hover > a {
    background-color: #919191;
    color: #f2f2f2;
}

.pull-right > .dropdown-menu {
    right: 0;
    left: auto;
    padding: 0;
    color: #5b5b5b;
}

.btn-gear
{
    border: none;
    padding: 0px;
    width: 42px;
    height: 38px;
    cursor: default;
}

.btn-gear:hover, .btn-gear:focus, .btn-gear:active, .btn-gear:focus:active, .btn-gear:focus:hover, .btn-gear:active:focus
{
    background-color: #5b5b5b;
    color: white;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn {
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn:focus
{
    background-color: var(--main-color);
}

.btn-warning
{
    background-color: #5b5b5b;
}

.btn-warning:hover, .btn-warning:focus:hover
{
    background-color: #747474;
    color: white;
    border: none;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #747474;
    color: white;
    border: none;
}

.btn-warning:focus,
.btn-warning.focus  {
    background-color: #5b5b5b;
    color: white;
    border: none;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
    -webkit-box-shadow: inset -5px 5px 40px 5px rgba(0, 0, 0, 0.3);
    box-shadow: inset -5px 5px 40px 5px rgba(0, 0, 0, 0.3);
    background-color: #d4d4d4;
    color: white;
}

.btn-box-tool i.fa
{
    font-size: 20px;
}

.btn-box-tool:active, .btn-box-tool:focus, .btn-box-tool:checked {
    background-color: unset !important;
    border: none;
    outline: none;
}

.btn-box-tool
{
    width: 42px;
    height: 42px;
    margin-right: -10px;
    margin-top: -5px;
}

.btn-tab-active
{
    background: var(--main-color);
}

.btn-tab:hover
{
    background: var(--main-color);
    color: white;
    height: 32px!important;
}

.btn-tab:active
{
    color: white;
    height: 32px!important;
}

.btn-tab:focus
{
    color: white;
    height: 32px!important;
}

.btn-tab:active:focus
{
    background-color: var(--main-color-dark);
    color: white;
    height: 32px!important;
}

.add-r
{
    width: 100%;
}

.btn-primary {
    border-radius: 0px;
    color: #fff;
    background-color: var(--main-color);
    border: 0px;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
    outline: none;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
    outline: none;
}

.btn:active:hover,
.btn:active:focus,
.btn:focus,
.btn:active.focus {
    outline: none;
}

pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color);
    cursor: default;

}

.btn-info:hover, .ui-dialog .ui-dialog-buttonpane button:hover {
    color: black;
    background-color: var(--main-color-grey);
    border-color: var(--main-color);
}

.btn-info:active, .ui-dialog .ui-dialog-buttonpane button:active {
    color: white;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    outline-color: var(--main-color) !important;
}

.btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color);
    outline-color: var(--main-color);
}

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color);
    outline-color: var(--main-color);
}

.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color-dark);
}

.btn-primary:hover, .pagination > .active > a:hover,
.btn-primary:active, .pagination > .active > a:active,
.btn-primary:focus, .pagination > .active > a:focus,
.btn-primary:checked, .pagination > .active > a:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);
    outline: none;
}

.btn:active, .btn:focus, .btn:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);
    outline: none;
}

.btn:active > a, .btn:focus > a, .btn:checked > a {
    background-color: var(--main-color);
    border-color: var(--main-color);
    outline: none;
    border: none;
}

.select2-container .select2-selection--single {
    border: 1px solid #d2d6de;
    border-radius: 0;
    height: 34px;
    display: block;
    color: #555555;
    padding: 6px 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    height: auto;
    margin-top: -4px;
    margin-left: 10px;
    padding: 0;
    line-height: 28px;
    color: #555555;
}

.pagination > .active > a {
    background-color: var(--main-color);
    border-color: var(--main-color);
}


/* Cначала обозначаем стили для IE8 и более старых версий
т.е. здесь мы немного облагораживаем стандартный чекбокс. */

.checkbox {
    vertical-align: top;
    margin: 0 3px 0 0;
    width: 32px;
    height: 17px;
}


/* Это для всех браузеров, кроме совсем старых, которые не поддерживают
селекторы с плюсом. Показываем, что label кликабелен. */

.checkbox + label {
    cursor: pointer;
}


/* Далее идет оформление чекбокса в современных браузерах, а также IE9 и выше.
Благодаря тому, что старые браузеры не поддерживают селекторы :not и :checked,
в них все нижеследующие стили не сработают. */


/* Прячем оригинальный чекбокс. */

.checkbox:not(checked) {
    position: absolute;
    opacity: 0;
    z-index: 10000;
    cursor: pointer;
}

.checkbox:not(checked) + label {
    position: relative;
    /* будем позиционировать псевдочекбокс относительно label */
}


/* Оформление первой части чекбокса в выключенном состоянии (фон). */

.checkbox:not(checked) + label:before {
    font-family: FontAwesome;
    content: "\f204";
    font-size: 21px;
}

/*.checkbox:not(checked)+label:after {
    content: " отключен";
}*/


/* Меняем фон чекбокса, когда он включен. */

.checkbox:checked + label:before {
    font-family: FontAwesome;
    content: "\f205";
    font-size: 21px;
    color: var(--main-color);
}

/*.checkbox:checked+label:after {
    content: " включен";
}*/

input[type='checkbox'],
input[type='radio'] {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
}

.status_1 {
    color: #ffc200;
}

.status_2 {
    color: #00ed00;
}

.status_3 {
    color: #b9b9b9;
}

.status_4 {
    color: #ff0602;
}

.status_5 {
    color: #3c8dbc;
}

.stm_1 {
    color: #3c8dbc;
}

.stm_2 {
    color: #00ed00;
}

.stm_3 {
    color: #d2d6de;
}

.stm_4 {
    color: #f39c12;
}

.stm_5 {
    color: #dd4b39;
}

.stm_6 {
    color: #dd4b39;
}

.stm_7 {
    color: #00c0ef;
}

.stm_8 {
    color: #39cccc;
}

.stm_9 {
    color: #f012be;
}

i.fa-pencil {
    cursor: pointer;
}

i.fa-trash {
    cursor: pointer;
}

i.fa-trash-o {
    cursor: pointer;
}

i.fa-eye {
    cursor: pointer;
}

i.fa-pencil.no-fa {
    color: #b9b9b9;
    cursor: auto;
}

i.fa-trash.no-fa {
    color: #b9b9b9;
    cursor: auto;
}

button.btn, .form-control, .input-sm, body, .content-wrapper a, input, .daterangepicker, .dropdown-menu
{
    font-size: 13px !important;
    font-family: "Arial" !important;
}

table.dataTable tbody td button.btn-default {
    border: 0;
    padding: 0;
    text-align: left;
}

table thead th, table tfoot th
{
    font-weight: normal !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background-image: none;
}

i.set_st {
    cursor: pointer;
}

.sub-block {
    border-bottom: 1px solid #d2d6de;
}

.sub-block-new {
    background: var(--sub-block-color);
    margin-bottom: 20px;
}

.sub-block-new .box-footer {
    background: none;
}

.box-info .box-header.box-header-active {
    border-bottom: solid 1px rgb(224, 224, 224);
}

.collapsed-box .box-header.box-header-active {
    border-bottom: 0px!important;
}

.box-header.with-border {
    border: none;
    background: #f5f5f5;
    padding: 7px 10px 3px;
    border-bottom: solid 1px rgb(224, 224, 224);

}

.box-header.with-dark-border
{
    -webkit-box-shadow: none;
    box-shadow: none;
    background: unset;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.pivotTableCustom .pvtAxisContainer, .pvtVals {
    border: 2px solid #F8F8F8;
    background: #ffffff;
}

.box-header-active {
    cursor: pointer;
    padding: 7px 10px 3px;
}

i.fa-user-plus {
    cursor: pointer;
}

button.prefix:after {
    font-family: FontAwesome;
    content: "\f090";
}

button.js-accordeon.eye.collapsed:after {
    content: "\f06e";
}

button.js-accordeon.eye:after {
    font-family: FontAwesome;
    content: "\f070";
}

button.js-accordeon.collapsed:after {
    content: "\f067";
}

button.js-accordeon:after {
    font-family: FontAwesome;
    content: "\f068";
}

#hidden_ip {
    display: none;
}

.ui-dialog {
    z-index: 1000 !important;
}

#end_time_work {
    visibility: hidden;
}

.drag-ul {
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.drag-li {
    margin: 5px;
    padding: 5px;
    width: inherit;
    cursor: pointer;
}

.drag-h6 {
    font-weight: normal;
    margin: 5px;
    padding: 5px;
}

.darg-input {
    width: 60px;
    display: inline-block;
    margin: 0 5px;
}

#block_pattern {
    display: none;
}

#check-pattern {
    cursor: pointer;
}

.drag-ul .form-group {
    margin: 0;
}

.drag-ul .box-body {
    border-bottom: 1px solid #ccc;
}

li {
    list-style-type: none;
}

.li-lable {
    font-weight: normal;
    margin-bottom: 7px;
}

.button-list {
    margin-top: 80px;
}

.list-right .li-item {
    padding: 2px 0;
}

.remove-asp-rulle {
    cursor: pointer;
}

.list-content .drag-ul .box-body {
    border: none
}

.list-content .btn-info.btn-xs {
    padding: 5px 6px;
    font-size: inherit;
}

.statisticCard {
    display: block;
    background: var(--sidebar-text-focus);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.sidebar-menu li > a > .pull-right-container > .fa-caret-right {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    -webkit-transition: transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
}

.sidebar-menu .menu-open > a > .pull-right-container > .fa-caret-right {
    -webkit-transform: rotate(+90deg);
    -ms-transform: rotate(+90deg);
    -o-transform: rotate(+90deg);
    transform: rotate(+90deg);
}

.load {
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.2);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.load .item {
    display: block;
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #1695A3;
    margin-left: 10%;
    margin-top: 100px;
    position: relative;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

.load .item.item4 {
    background-color: transparent;
    animation: circle2 1s linear infinite;
}

.load .item.item4 .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: 0%;
    border-radius: 50%;
}

.load .item.item4 .circle:before {
    content: '';
    width: 30%;
    height: 30%;
    border-radius: 50%;
    background-color: #1695A3;
    position: absolute;
    top: -15%;
    left: 35%;
}

.load .item.item4 .circle.circle1 {
    transform: rotate(0);
    opacity: 0.2;
}

.load .item.item4 .circle.circle2 {
    transform: rotate(40deg);
    opacity: 0.4;
}

.load .item.item4 .circle.circle3 {
    transform: rotate(80deg);
    opacity: 0.6;
}

.load .item.item4 .circle.circle4 {
    transform: rotate(120deg);
    opacity: 0.8;
}

.load .item.item4 .circle.circle5 {
    transform: rotate(160deg);
    opacity: 1;
}

@keyframes circle2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.callout.callout-warning {
    border-color: #787878;
    background-color: #c2c2c2 !important;
}

.col1
{
    text-decoration: none;
    color: #212322;
}

.col1:hover
{
    color: #212322;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-text-decoration-color: #bababa;
    text-decoration-color: #bababa;
    -webkit-text-decoration-style: 2px solid;
    text-decoration-style: 2px solid;
}

.col2
{
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-text-decoration-color: var(--main-color);
    text-decoration-color: var(--main-color);
    color: var(--main-color);
}

.col2:hover
{
    color: var(--main-color);
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-text-decoration-color: #bababa;
    text-decoration-color: #bababa;
    -webkit-text-decoration-style: 2px solid;
    text-decoration-style: 2px solid;
}

.top-header {
    display: block;
    min-height: 80px;
    background: #fff;
}

.content-wrapper,
.right-side {
    background-color: #f1f1f1;
}

.wrapper {
    overflow: hidden;
}

.box-info
{
    border: solid 1px #d3d3d3;
}

.box.box-info {
    border: 1px solid rgb(224, 224, 224);
}

.box.box-primary
{
    border-top-color: #999999;
}

.nav-icons
{
    width: 40px;
}

.nav-tabs-custom > .nav-tabs > li.active {
    border: 1px solid rgb(224, 224, 224);
}

.nav-tabs-custom > .nav-tabs {
    background: #eee;
    border: 1px solid rgb(224, 224, 224) !important;
}

.nav-tabs-custom > .nav-tabs > li.active,
.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
    background-color: #505256;
    color: #ffffff;
    border: 0;
}

.nav-tabs-custom > .nav-tabs > li > a:hover
{
    color: white;
    background-color: #505256;
}

.nav-tabs-custom > .nav-tabs > li {
    background-color: #787878;
    margin: 0;
    color: rgb(255, 255, 255);
    border: 0;
    margin-right: 1px;
}

.nav-tabs-custom > .nav-tabs > li a {
    color: rgb(255, 255, 255);
    border: 0;
}

.breadcrumb {
    background: transparent;
    padding: 0px;
}

.breadcrumb a {
    color: #6a6a6a;
}

thead {
    background-color: var(--main-color);
    color: #fff;
}

thead th {
    text-align: center;
    border: 0 !important;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
    opacity: 1;
}

/*
table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::after {
    opacity: 0.5;
} */

thead th:focus, thead th:active, thead th:active:hover {
    border-color: var(--main-color);
}

thead td {
    background-color: var(--main-color);
    color: #fff;
}

thead td:after {
    color: #fff;
}

.table-hover > tbody > tr:hover {
    background-color: #faf3d7;
    border-color: #faf3d7;
}

.table-hover > tbody > tr:hover td {
    background-color: #e6e6e6;
    border-color: #dddddd;
}

table.dataTable tbody th, table.dataTable tbody td
{
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    height: 36px;
}

tbody tr {
    background-color: #f9f9f9 !important;
    height: 38px;
}

tbody tr:nth-of-type(2n) {
    background-color: #f5f5f5 !important;
}

table.dataTable.no-footer {
    border-bottom: 0px solid rgb(17, 17, 17);
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0;
    margin: 0;
    border: 1px solid rgb(224, 224, 224);
    background: #fff;
}

.table-bordered th {
    text-align: left;
}

td .btn-default,
td .btn-default:hover {
    border: 1px solid rgb(224, 224, 224);
    background: transparent;
}

.pagination > li > a:hover,
.pagination > li > a,
.pagination > li > span:hover,
.pagination > li > span,
.pagination > li > a:focus,
.pagination > li > a,
.pagination > li > span:focus {
    border: 0;
    background: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid rgb(224, 224, 224);
    background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    border: 1px solid #dddddd;
    background: #ffffff;
    padding: 0;
    margin: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid var(--main-color);
    background: var(--main-color);
}

.dataTables_wrapper .dataTables_paginate .paginate_button a,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled a,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled a:hover {
    border: none;
    background: transparent;
    color: #777777;
}

.dataTables_wrapper .dataTables_paginate .paginate_button a:hover {
    border: none;
    background: transparent;
    color: #ffffff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active,
.dataTables_wrapper .dataTables_paginate .paginate_button.active:hover {
    border: 1px solid var(--main-color);
    background: var(--main-color);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active a,
.dataTables_wrapper .dataTables_paginate .paginate_button.active:hover a {
    border: none;
    background: transparent;
    color: #ffffff;
}

.nav-tabs-custom > .tab-content,
.box.box-info,
.box-footer,
.ui-dialog .ui-dialog-buttonpane,
.login-box-body {
    background: #f9f9f9;
    box-shadow: none;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-dialog-buttons.ui-draggable {
    background: #f9f9f9;
}

.input-group .input-group-addon,
.form-control {
    border: 1px solid rgb(224, 224, 224);
}

.form-control:focus, .select2-container--default .select2-selection--multiple:focus
{
    border: solid 1px var(--main-color);
    -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}

.form-control:focus
{
    border: solid 1px var(--main-color);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.ui-dialog .ui-dialog-buttonpane button,
.btn-info {
   margin: .5em .4em .5em 0;
    cursor: pointer;
    color: #fff;
    border-radius: 0;
    background: var(--main-color-grey);
    border: 1px solid var(--border-grey);
    height: 34px;
    color: black;
    padding: 8px 50px;
}

.ui-dialog .box-header.with-border {
    background: #f9f9f9;
    box-shadow: none;
}

.treeview-menu {
}

.main-sidebar li a
{
    font-size: 13px;
}

.skin-blue .sidebar-menu > li > .treeview-menu {
    margin: 0 1px;
    background: #34373e;
}

.treeview-menu i.fa.fa-circle-o {
    visibility: hidden;
}

.skin-blue .sidebar-menu > li:hover > a,
.skin-blue .sidebar-menu > li.active > a {
    border: 0 !important;
}

i.fa.fa-user-plus {
    vertical-align: middle;
}

.ui-dialog {
    z-index: 10000 !important;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-dialog-buttons.ui-draggable {
    min-width: 370px;
}

.select2-dropdown {
    z-index: 10051;
}

.select2-dropdown .select2-search__field:focus, .select2-search--inline .select2-search__field:focus:focus
{
    border: none;
}

.points {
    display: inline;
    margin-top: -5px;
    margin-left: -30px;
    position: relative;
    z-index: 0;
}

.ttl_info{
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    padding-top: 7px;
}

.ttl_input{
    width: 100%;
    min-width: 100px;
}

.treeview-menu > li > a > span{
    display: block;
}

/*verstka sergey yashichev*/
.skin-blue .sidebar-menu > li > a {
    border: none;
    cursor: pointer;
}

.skin-blue .treeview-menu > li > a {
    color: #93969c;
    cursor: pointer;
}

.skin-blue .treeview-menu > li > a span {
    padding-left: 7px;
}

.sidebar-menu > li > a {
    padding: 12px 5px 11px 17px;
}

.sidebar-menu .treeview-menu > li > a > .fa.fa-circle-o {
    width: 13px;
}

.skin-blue .sidebar a {
    color: #b7b9bd;
    padding: 12px 17px 11px 17px;
}

.skin-blue .sidebar-menu > li > a span {
    padding-left: 10px;
}

.skin-blue .treeview-menu > li .treeview-menu > li > a span {
    padding-left: 20px;
}

.content-header {
    padding-top: 0px;
}

.breadcrumb > li + li:before {
    font-family: 'FontAwesome';
    content: "\f105";
    padding: 0 5px;
    color: #555555;
}

.breadcrumb > li > a {
    text-decoration: underline;
}

.breadcrumb > li > a:hover {
    text-decoration: none;
}

.breadcrumb > li i.fa-home {
    color: #555555;
    padding-right: 7px;
}

.content-header > h1 {
    margin: 7px 0 5px;
    color: #555555;
}

.nav-tabs-custom > .nav-tabs > li a i {
    padding-right: 10px;
}

i.fa.fa-list-conf:before {
    content: "";
    background-image: url(/img/list.png);
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
}

.select2-container--default .select2-search--inline .select2-search__field:focus
.select2-container--default .select2-selection--multiple:focus
{
    border: solid 1px var(--main-color);
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: 0;
    background: #fff;
    clip-path: polygon(0% 0, 0% 100%, 100% 50%);
    -webkit-clip-path: polygon(0% 0, 0% 100%, 100% 50%);
    width: 6px;
    height: 10px;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 32px;
    right: 0px;
    background: rgb(81, 82, 87);
    color: #fff;
    width: 32px;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight,
.drag-li.ui-sortable-handle {
    border: 1px solid rgb(234, 234, 223);
    background: rgb(255, 250, 231);
    color: rgb(103, 99, 88);
}

.bootstrap-select {
    min-width: 100px !important;
    max-width: 150px !important;
    width: 100% !important;
    background: rgb(98, 98, 98) !important;
    color: #fff !important;
}

.bootstrap-select .btn.dropdown-toggle.btn-info {
    margin: 0;
    background: rgb(98, 98, 98) !important;
    color: #fff !important;
    border: 0;
}

.bootstrap-select .btn.dropdown-toggle.btn-info:hover {
    border: 0;
}

.bootstrap-select .btn.dropdown-toggle.btn-info .caret {
    border: 0;
    background: #fff;
    clip-path: polygon(0% 0, 0% 100%, 100% 50%);
    -webkit-clip-path: polygon(0% 0, 0% 100%, 100% 50%);
    width: 6px;
    height: 10px;
    right: 12px;
    margin: auto;
    top: 0;
    bottom: 0;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

.btn-ur
{
    width: 30px;
    height: 30px;
    border: none;
}

.open > .dropdown-toggle.btn-warning > span.caret
{
    display: none;
}

.open > .dropdown-toggle.btn-warning > span.caret-down
{
    display: inline-block;
}


.caret
{
    position: absolute;
    top: 15px;
    left: 16px;
    display: inline-block;
    width: 0px;
    height: 0px;
    vertical-align: middle;
    border: 4px solid transparent;
    border-left: 4px solid;
    margin-left: 4px !important;
    margin-bottom: 4px;
}

.caret-down
{
    position: absolute;
    top: 18px;
    left: 16px;
    display: none;
    width: 0px;
    height: 0px;
    vertical-align: middle;
    border: 4px solid transparent;
    border-top: 4px solid;
}


.bootstrap-select.open .btn.dropdown-toggle.btn-info .caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bootstrap-select .dropdown-menu {
    border-radius: 0;
}

.bootstrap-select .dropdown-menu li a:before {
    display: inline-block;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid rgb(170, 169, 169);
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 5px;
    background: #fff;
}

.bootstrap-select .dropdown-menu li a span {
    vertical-align: middle;
}

.bootstrap-select .dropdown-menu li.selected,
.bootstrap-select .dropdown-menu li.selected a {
    background-color: rgb(154, 161, 169);
}

.bootstrap-select .dropdown-menu li.selected a {
    color: #fff;
}

.bootstrap-select .dropdown-menu li.selected a:after {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: rgb(154, 161, 169);
    border-radius: 100%;
    position: absolute;
    top: 10px;
    left: 24px;
}

.bootstrap-select > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
    position: absolute;
    margin: auto !important;
    left: 0;
    right: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}

.bootstrap-select > .dropdown-toggle span {
    text-align: center !important;
}

.min + .select2-container * {
    background: rgb(98, 98, 98) !important;
    color: #fff !important;
}

.min + .select2-container .select2-selection__arrow b {
    background: rgb(255, 255, 255) !important;
    width: 4px !important;
    height: 8px !important;
}

.dataTables_wrapper .input-group {
    display: flex;
    margin-bottom: 5px;
}

.dataTables_wrapper .input-group input {
    padding: 5px 10px;
    height: 30px;
}

.dropdown.user.user-menu li.user-head {
    background-color: #232428;
}

.dropdown.user.user-menu li.user-head a, .dropdown.user.user-menu li.user-head span {
    color: #ffffff;
    font-weight: normal;
}

.dropdown.user.user-menu li:last-child {
    border-top: 1px solid #232428;
}

.dropdown.user.user-menu li.user-head a:hover {
    background: transparent;
}

#group-numb {
    padding-bottom: 20px;
}

#search-list-container {
    display: none;
}

.new_mailing {
    margin: 80px 0 20px;
}

.mt15 {
    margin-top: 15px;
}

.sub-block-new .box-header.with-border {
    background: transparent;
    margin-bottom: 20px;
}

.flex-wrapper {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: left;
    }
}

/*.sidebar-menu {
    margin-top: 90px;
}

@media (max-width: 767px) {
    .sidebar-menu {
        margin-top: 110px;
    }
}*/

@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > button > span {
        display: none !important;
    }

    .sidebar-mini.sidebar-collapse .sidebar-menu > #start_mailing > a > button {
        background-color: transparent;
        border-color: transparent;
        padding: 0px;
    }

    .sidebar-mini.sidebar-collapse .sidebar-menu > #start_mailing > a > button > i {
        display: block;
    }

    .user-avatar
    {
        height: 54px;
        padding-top: 16px !important;
    }

    .navbar-nav > .user-menu .user-image
    {
        margin-top: -7px;
    }

}

.sidebar
{
    background-color: var(--sidebar-bg);
}

.pointer {
    cursor: pointer;
}

.quota {
    font-size: 19px;
    margin-right: 10px;
}

.morris-hover.morris-default-style {
    border-radius: 10px;
    padding: 6px;
    color: #f9f9f9;
    background: rgba(0, 0, 0, 0.8);
    border: solid 2px rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.morris-hover {
    position: absolute;
    z-index: 1090;
    display: none;
}

.container-morris {
    position: relative;
}

.container-morris:hover .morris-hover {
    display: block;
}

.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: 0.1em 0;
    font-weight: normal;
}

.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: normal;
    margin: 0.25em 0;
}

.text-wrap {
    white-space: nowrap;
}

.width-icons {
    width: 1% !important;
}

#kanals button.js-accordeon {
    padding: 6px 5px 3px;
}


/*  visual */
.visual tspan {
    font-weight: normal !important;
    font-size: 13px;
}

.visual text.highcharts-title tspan {
    font-size: 16px;
}

.visual .switch {
    margin-right: 12px;
    vertical-align: top;
}

.visual input.checkboxOnOff {
    margin: 5px;
}

.visual .visual_btn {
    margin-top: 20px;
}

.visual .reset_zoom {
    margin-right: 12px;
}

.visual .visual.box {
    border-top: 0px;
}

.visual .chartBlock {
    background: #f9f9f9;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 5px;
}

.visual .pieBlock {
    background: #f9f9f9;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    padding: 1px 15px 15px;
}

.visual h2.pieHeader {
    margin-top: 10px;
}

.visual .visual.content {
    padding-top: 0px;
}

.visual li.active .nav-btn-primary {
    float: none;
    background: var(--main-color) !important;
}

.visual .filter > div label.active, :checked + label.btn {
    float: none;
    background: var(--main-color) !important;
}

.visual .nav .btn-primary, .nav > li .nav-btn-primary {
    margin: 0;
    cursor: pointer;
    border: 1px solid rgb(204, 204, 204);
    background: #424242;
    color: #fff;
    padding-bottom: 6px;
    padding-top: 8px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: normal;
}

.visual .tag {
    border-radius: 0;
    height: 36px;
    position: static;
    padding: 9px 10px;
    padding-top: 11px;
    border: 1px solid var(--border-grey);
}

.visual .cancelBtn.btn.btn-sm.btn-default {
    display: none;
}

.visual .showErrors.active .hideElement {
    max-height: 1000px;
    box-shadow: 1px 1px 1px rgb(136, 136, 136);
    border: 1px solid rgb(209, 209, 209);
}

.visual .showErrors.active {
}

.visual .showErrors .fa.fa-caret-right {
    transition: transform .5s ease, -webkit-transform .5s ease;
}

.visual .showErrors.active .fa.fa-caret-right {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.visual .loadImg {
    position: relative;
    z-index: 1;
}

.visual .loadImg img {
    max-width: 50px;
}

.visual .showErrors {
    cursor: pointer;
}

.visual .ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-dialog-buttons.ui-draggable {
    min-width: 370px;
}

.visual label.btn {
}

.visual .text-checkbox__input:checked + .text-checkbox__label {
    background: var(--main-color);
    border-color: var(--main-color-dark);
    color: white;
}

.visual :checked + label.btn {
    background: rgb(22, 82, 113);
}

.visual .radioHide input[type='radio'] {
    display: none;
}

.visual label.btn input[type='text'] {
    border: none;
    background: transparent;
}



.visual .showErrors .hideElement {
    cursor: normal;
    max-height: 0;
    overflow: hidden;
    transition: transform .5s ease, -webkit-transform .5s ease;
    background: #fff;
    border-radius: 5px;
    margin-top: 5px;
    width: 98%;
}

.visual .showErrors .hideElement input {
    margin-right: 3px;
    margin-left: 3px;
}

.visual .filter {
    position: absolute;
    top: 220px;
    right: 0;
    z-index: 1;
    width: 212px;
}

.visual .filter > div {
    margin: 10px 0;
}

.visual .filter > div input {
    display: inline-block;
}

.visual .filter > div label {
    float: none !important;
    background: transparent;
    border: none;
    color: #000;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    max-width: 170px;
    font-size: 10px;
    vertical-align: middle;
}

.visual .datePick {
    cursor: pointer;
    width: 240px;
    font-weight: normal;
}

.visual .periodBtn {
    padding-top: 7px;
}

/*  visual end */

/*  statTable */
.statTable .ui-sortable {
    font-size: 0;
    min-height: 40px;
    background: #fff;
    box-sizing: border-box;
    padding: 3px;
}

.statTable .nav .btn-primary {
    margin: 0;
    cursor: pointer;
    border: 1px solid rgb(204, 204, 204);
    background: #424242;
    color: #fff;
    border-radius: 0;
    padding-bottom: 6px;
    padding-top: 8px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: normal;
}

.statTable .ui-sortable li {
    display: inline-block;
    list-style-type: none;
    padding: 5px 10px;
    font-size: 12px;
    margin: 2px;
}

.statTable .ui-sortable.droptrue li {
    background-color: var(--stat-table-buttons-color);
}

.statTable .ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-dialog-buttons.ui-draggable {
    min-width: 370px;
}

.statTable :checked+label.btn {
    background: rgb(22, 82, 113);
}

.statTable .radioHide input[type='radio'] {
    display: none;
}

.statTable label.btn input[type='text'] {
    border: none;
    background: transparent;
}

.statTable .filter {
    position: absolute;
    top: 110px;
    right: 0;
    z-index: 1;
    width: 200px;
}

.statTable .filter>div label {
    float: none !important;
    background: #000;
    border: none;
    color: #000;
    padding: 5px;
    margin: 0;
}

.statTable .showErrors {
    cursor: pointer;
}

.showErrors .hideElement {
    cursor: normal;
    max-height: 0;
    overflow: hidden;
    transition: transform .5s ease, -webkit-transform .5s ease;
    background: #fff;
}

.statTable .showErrors.active .hideElement {
    max-height: 1000px;
}

.statTable .showErrors .fa.fa-caret-right {
    transition: transform .5s ease, -webkit-transform .5s ease;
}

.statTable .showErrors.active .fa.fa-caret-right {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.statTable .loadImg img {
    max-width: 50px;
}

/*.tag {
    border-radius: 0;
    top: 7px;
    position: relative;
    padding: 10px 10px;
    border: 0;
    height: auto;
}*/

.statTable td {
    text-align: center;
}

.statTable .cancelBtn.btn.btn-sm.btn-default {
    display: none;
}

.statTable .filter>div label:hover,
.filter>div label.active,
:checked+label.btn {
    float: none;
    background: var(--main-color);
}

.statTable .box {
    border-top: 0px;
}

.statTable .nav-tabs-custom > .tab-content {
    padding: 10px 0px;
}

.statTable #showTime > .tab-content {
    padding: 10px 10px;
}

.statTable .tab-pane#config {
    padding: 10px;
}

.statTable #datatable-index-job_wrapper .row {
    margin: 0px;
}

.statTable #datatable-index-job_wrapper .row .col-sm-12 {
    padding: 0px;
}

.statTable .pivotTableCustom .pvtVertList{
    display: inline-grid;
}

.statTable .pivotTableCustom .pvtFilterBox {
    top: inherit!important;
    left: inherit!important;
}

.statTable .pivotTableCustom .ui-sortable {
    font-size: inherit!important;
}

.statTable .pivotTablePlot .pvtAttrDropdown {
    visibility: hidden;
}

.statTable body {
    font-family: Verdana;
}

.statTable .node {
    border: solid 1px white;
    font: 10px sans-serif;
    line-height: 12px;
    overflow: hidden;
    position: absolute;
    text-indent: 2px;
}

.statTable .radioHide {
    background: rgb(249, 249, 249);
    border: 0 !important;
    padding: 0px;
}

.statTable table.pvtTable thead tr th, table.pvtTable tbody tr th {
    color: #333333;
    border: 1px solid #CDCDCD !important;
    background: var(--stat-table-color)!important;
}
/*  statTable end */

/*  statPivot */
.statPivot .pivotTableCustom .pvtVertList {
    display: inline-grid;
}

.statPivot .lablePivot {
    padding: 1rem 0 0;
}

.statPivot .nav-tabs-custom {
    padding: 0 11px;
}

.statPivot .pivotTableCustom .pvtVals {
    text-align: left;
    border-left: none!important;

}

.pivotTableCustom .pvtAxisContainer, .pvtVals {
    border: 2px solid #F8F8F8!important;
    background: #ffffff!important;
}

.statPivot .pivotTableCustom .pvtRows.pvtUiCell.ui-sortable {
    border-left: none;
}

.statPivot .pivotTableCustom .pvtFilterBox {
    border: 1px solid #d2d6de;
    top: inherit !important;
    left: inherit !important;
}

.statPivot .pivotTableCustom .ui-sortable {
    font-size: inherit !important;
}

.statPivot .pivotTablePlot .pvtAttrDropdown {
    display: none;
}

.statPivot .pivotTableCustom .pvtAxisContainer,
.pvtVals {
    border: 2px solid #F8F8F8;
    background: #ffffff;
}

.statPivot .pivotTableCustom .pvtUi {
    background: #ffffff;
    display: block;
    width: 100%;
    overflow: auto;
}

.statPivot .pivotTableCustom .pvtHorizList {
    border-right: none!important;
}

.statPivot .pivotTableCustom .pvtAxisContainer li {
    margin: 0.5rem 0;
}

.statPivot .pivotTableCustom .pvtUiCell {
    padding: 0.5rem;
}

.statPivot .pivotTableCustom .pvtAxisContainer {
    display: flex;
    flex-wrap: wrap;
    border-top: none!important;
}

.statPivot .pivotTableCustom .pvtAxisContainer li span.pvtAttr {
    background: var(--stat-table-color);
    border-radius: 0px;
    padding: 1rem;
}

.statPivot .pivotTableCustom table.pvtTable {
    background: #ffffff;
    border: 2px solid var(--stat-table-border) !important;
    color: var(--stat-table-color-font);
    width: unset !important;
}

.statPivot .pivotTableCustom select {
    border: 2px solid var(--stat-table-select);
    margin: 0.5rem 0.6rem;
    padding: 0.5rem;
    background: #ffffff;
}

.statPivot .pivotTableCustom .pvtAggregator {
    display: none;
}

.statPivot .pivotTableCustom .pvtRendererArea {
    padding: 1.1rem;
    width: 100%;
}

.statPivot .pivotTableCustom .pvtAxisContainer.pvtHorizList.pvtCols.pvtUiCell.ui-sortable {
    border-top: none!important;
    border-left: none!important;
}

.statPivot .pivotTableCustom table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    background-color: var(--stat-table-color);
    border: 2px solid var(--stat-table-border) !important;
}

.statPivot .pivotTableCustom thead th {
    text-align: inherit;
    border: 2px solid var(--stat-table-border) !important;
    color: var(--stat-table-color-font);
}

.statPivot .pivotTableCustom button:focus,
pvtFilterBox {
    color: #fff;
    background-color: var(--main-color-dark);
    border-color: var(--main-color);
}

.statPivot .pivotTableCustom button:hover,
pvtFilterBox {
    color: #fff;
    background-color: var(--main-color-dark);
    border-color: var(--main-color);
    outline: none;
}

.statPivot .pivotTableCustom button:active,
pvtFilterBox {
    color: #fff;
    background-color: var(--main-color-dark);
    border-color: var(--main-color);
}

.statPivot .pivotTableCustom button,
pvtFilterBox {
    -webkit-appearance: button;
    cursor: pointer;
    background-color: var(--main-color);
    border-color: #008d4c;
    color: #fff;
    border: 1px solid transparent;
    margin: 5px;
    padding: 5px 10px;
}

.statPivot .pivotTableCustom .pvtFilterBox input[type='text'] {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 0 5px 0;
    padding: 0 6px 0 28px;
    line-height: 30px;
    width: 80%;
}

.statPivot .pivot.box {
    border-top: none;
}

.statPivot .pivot.tab-content {
    padding-bottom: 10px;
}
/*  statPivot end */

button.btn-default {text-align: center !important;}

.other_block{
    border-bottom: 1px solid lightgray;
}

.has-error-speed .form-control {
    border-color: #a94442;
}
.pt-8 {padding-top: 8px;}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    margin: 0;
    padding: 0;
}
.select2-container .select2-search--inline .select2-search__field {
  /*  margin-top: 7px;padding: 0 12px; */
    margin: 7px 12px 0 12px
}
.select2-search__field {
    width: 100% !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-left:12px;
}
.pvtFilterBox {
    max-height:300px !important;
    overflow: auto !important;
}
.pvtCheckContainer {
    overflow: auto !important;
    max-height: none !important;
}
.select2-results__options[aria-multiselectable=true] .select2-results__option[aria-selected=true] {
    display: none;
}